var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("breadcrumb-header", {
        attrs: {
          labels: [_vm.client.name, "Link project"],
          go_back_to: "management_client_detail",
          go_back_params: {
            client_id: _vm.$route.params.client_id,
          },
        },
      }),
      _c(
        "div",
        { staticClass: "inner" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form-wrapper",
              attrs: { "auto-complete": "on", "label-width": "200px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Existing project", prop: "project" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        value: _vm.project,
                        placeholder: "Select",
                        "value-key": "id",
                      },
                      on: { change: _vm.changedProject },
                    },
                    _vm._l(_vm.projects, function (p) {
                      return _c("el-option", {
                        key: p.id,
                        attrs: { label: p.name, value: p },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: !_vm.project },
                  on: { click: _vm.linkProjectToClient },
                },
                [_vm._v(" Link ")]
              ),
            ],
            1
          ),
          _c("hr"),
          _c("projects-new", {
            attrs: { client: _vm.client },
            on: { created: _vm.onProjectCreated },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }